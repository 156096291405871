import facebook from 'assets/images/icons/facebook.png';
import twitter from 'assets/images/icons/twitter.png';
import instagram from 'assets/images/icons/instagram.png';
import github from 'assets/images/icons/github.png';
import dribbble from 'assets/images/icons/dribbble.png';

export const menuItems = [
  {
    id: 2,
    title: 'About Us',
    items: [
      // {
      //   path: '#!',
      //   label: 'Support Center',
      // },
      {
        path: '#!',
        label: 'Customer Support',
      },
      {
        path: '#!',
        label: 'About Us',
      },
      {
        path: '#!',
        label: 'Copyright',
      }
    ],
  },
  {
    id: 3,
    title: 'Our Information',
    items: [
      {
        path: '#!',
        label: 'Return Policy ',
      },
      {
        path: '#!',
        label: 'Privacy Policy',
      },
      {
        path: '#!',
        label: 'Terms & Conditions',
      },
      // {
      //   path: '#!',
      //   label: 'Site Map',
      // }
    ],
  },
  {
    id: 4,
    title: 'My Account',
    items: [
      {
        path: '#!',
        label: 'Press inquiries',
      },
      {
        path: '#!',
        label: 'Social media ',
      },
      {
        path: '#!',
        label: 'directories',
      },
      // {
      //   path: '#!',
      //   label: 'Images & B-roll',
      // },
      // {
      //   path: '#!',
      //   label: 'Permissions',
      // },
    ],
  },
  {
    id: 5,
    title: 'Connect',
    items: [
      {
        path: 'https://www.facebook.com/redz.evoura.in',
        icon: facebook,
        label: 'Facebook',
      },
      {
        path: 'https://www.instagram.com/redz.app',
        icon: instagram,
        label: 'Instagram',
      },
      // {
      //   path: '#!',
      //   icon: github,
      //   label: 'Github',
      // },
      {
        path: '#!',
        icon: dribbble,
        label: 'Dribbble',
      },
    ],
  },
];

export const footerNav = [
  {
    path: '#!',
    label: 'Home',
  },
  {
    path: '#!',
    label: 'Advertise',
  },
  {
    path: '#!',
    label: 'Supports',
  },
  {
    path: '#!',
    label: 'Marketing',
  },
  {
    path: '#!',
    label: 'FAQ',
  },
];
