const menuItems = [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'services',
    label: 'Services',
  },
  {
    path: 'features',
    label: 'Feature',
  },
  // {
  //   path: 'pricing',
  //   label: 'Pricing',
  // },
  // {
  //   path: 'our-clients',
  //   label: 'Our Clients',
  // },
  {
    path: 'blog',
    label: 'Blog',
  },
  {
    path: 'faq',
    label: 'Faq',
  }
];

export default menuItems;
